.table {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'top-left top-right'
    'board board'
    'bottom-left bottom-right';
  gap: var(--size-small-5);
}

.topLeftPlayer {
  grid-area: top-left;
}

.topRightPlayer {
  grid-area: top-right;
  text-align: right;
}

.bottomLeftPlayer {
  grid-area: bottom-left;
}

.bottomRightPlayer {
  grid-area: bottom-right;
  text-align: right;
}

.player {
  font-size: var(--size-small-1);
  text-transform: uppercase;
  letter-spacing: var(--size-small-9);
  color: var(--foreground-color);
}

.player.blue {
  color: var(--player-blue-opaque);
}

.player.green {
  color: var(--player-green-opaque);
}

.player.red {
  color: var(--player-red-opaque);
}

.player.yellow {
  color: var(--player-yellow-opaque);
}

.board {
  border: var(--border-width-light) solid var(--board-border-color);
  grid-area: board;
}

.space {
  stroke: var(--space-stroke-color);
  fill: var(--background-color);
}

.outline {
  fill: none;
  stroke: var(--current-piece-outline-color);
}

.piece.blue {
  fill: var(--player-blue);
}

.piece.green {
  fill: var(--player-green);
}

.piece.red {
  fill: var(--player-red);
}

.piece.yellow {
  fill: var(--player-yellow);
}

.pieceContainer.animated {
  opacity: 0;
  transition: opacity var(--transition-short);
}

.pieceContainer.animated.shown {
  opacity: 1;
}

.recentPiece {
  fill: none;
}

.recentPiece.blue {
  stroke: var(--player-blue-accent);
}

.recentPiece.green {
  stroke: var(--player-green-accent);
}

.recentPiece.red {
  stroke: var(--player-red-accent);
}

.recentPiece.yellow {
  stroke: var(--player-yellow-accent);
}

.symbol {
  fill: var(--high-contrast-symbol-color);
}

.hint {
  opacity: 0.65;
}

.hint.blue {
  fill: var(--player-blue-accent);
}

.hint.green {
  fill: var(--player-green-accent);
}

.hint.red {
  fill: var(--player-red-accent);
}

.hint.yellow {
  fill: var(--player-yellow-accent);
}
