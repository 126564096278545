@import './reset.css';

/* Constants */

:root {
  /* Sizes */

  --ratio: calc(3 / 4);

  --ratio-2: calc(var(--ratio) * var(--ratio));
  --ratio-3: calc(var(--ratio-2) * var(--ratio));
  --ratio-4: calc(var(--ratio-3) * var(--ratio));
  --ratio-5: calc(var(--ratio-4) * var(--ratio));
  --ratio-6: calc(var(--ratio-5) * var(--ratio));
  --ratio-7: calc(var(--ratio-6) * var(--ratio));
  --ratio-8: calc(var(--ratio-7) * var(--ratio));
  --ratio-9: calc(var(--ratio-8) * var(--ratio));
  --ratio-10: calc(var(--ratio-9) * var(--ratio));
  --ratio-11: calc(var(--ratio-10) * var(--ratio));
  --ratio-12: calc(var(--ratio-11) * var(--ratio));
  --ratio-13: calc(var(--ratio-12) * var(--ratio));
  --ratio-14: calc(var(--ratio-13) * var(--ratio));

  --size-regular: 16px;

  --size-small-1: calc(var(--size-regular) * var(--ratio));
  --size-small-2: calc(var(--size-regular) * var(--ratio-2));
  --size-small-3: calc(var(--size-regular) * var(--ratio-3));
  --size-small-4: calc(var(--size-regular) * var(--ratio-4));
  --size-small-5: calc(var(--size-regular) * var(--ratio-5));
  --size-small-6: calc(var(--size-regular) * var(--ratio-6));
  --size-small-7: calc(var(--size-regular) * var(--ratio-7));
  --size-small-8: calc(var(--size-regular) * var(--ratio-8));
  --size-small-9: calc(var(--size-regular) * var(--ratio-9));

  --size-large-1: calc(var(--size-regular) / var(--ratio));
  --size-large-2: calc(var(--size-regular) / var(--ratio-2));
  --size-large-3: calc(var(--size-regular) / var(--ratio-3));
  --size-large-4: calc(var(--size-regular) / var(--ratio-4));
  --size-large-5: calc(var(--size-regular) / var(--ratio-5));
  --size-large-6: calc(var(--size-regular) / var(--ratio-6));
  --size-large-7: calc(var(--size-regular) / var(--ratio-7));
  --size-large-8: calc(var(--size-regular) / var(--ratio-8));
  --size-large-9: calc(var(--size-regular) / var(--ratio-9));
  --size-large-10: calc(var(--size-regular) / var(--ratio-10));
  --size-large-11: calc(var(--size-regular) / var(--ratio-11));
  --size-large-12: calc(var(--size-regular) / var(--ratio-12));
  --size-large-13: calc(var(--size-regular) / var(--ratio-13));
  --size-large-14: calc(var(--size-regular) / var(--ratio-14));

  --size-px: 1px;
  --size-px-2: calc(var(--size-px) * 2);
  --line-height: calc(1 / var(--ratio));

  /* Borders */

  --border-width-light: var(--size-px);
  --border-width-normal: var(--size-px-2);

  --border-radius: 4px;

  /* Fonts */

  --font-family: 'Work Sans';

  /* Weights */

  --font-weight-light: 275;
  --font-weight-normal: 425;
  --font-weight-heavy: 660;

  /* Transitions */

  --transition-short: 150ms ease;

  /* Colors - base */

  --background-color-r: 244;
  --background-color-g: 244;
  --background-color-b: 244;
  --background-color: rgb(
    var(--background-color-r),
    var(--background-color-g),
    var(--background-color-b)
  );

  --foreground-color-r: 28;
  --foreground-color-g: 31;
  --foreground-color-b: 35;
  --foreground-color: rgb(
    var(--foreground-color-r),
    var(--foreground-color-g),
    var(--foreground-color-b)
  );

  --player-blue-r: 45;
  --player-blue-g: 166;
  --player-blue-b: 241;
  --player-blue: rgba(
    var(--player-blue-r),
    var(--player-blue-g),
    var(--player-blue-b),
    var(--ratio)
  );
  --player-blue-opaque: rgb(
    var(--player-blue-r),
    var(--player-blue-g),
    var(--player-blue-b)
  );
  --player-blue-transparent: rgba(
    var(--player-blue-r),
    var(--player-blue-g),
    var(--player-blue-b),
    var(--ratio-8)
  );
  --player-blue-accent: rgba(
    calc((var(--player-blue-r) + var(--foreground-color-r)) / 2),
    calc((var(--player-blue-g) + var(--foreground-color-g)) / 2),
    calc((var(--player-blue-b) + var(--foreground-color-b)) / 2),
    var(--ratio)
  );

  --player-green-r: 138;
  --player-green-g: 201;
  --player-green-b: 38;
  --player-green: rgba(
    var(--player-green-r),
    var(--player-green-g),
    var(--player-green-b),
    var(--ratio)
  );
  --player-green-opaque: rgb(
    var(--player-green-r),
    var(--player-green-g),
    var(--player-green-b)
  );
  --player-green-transparent: rgba(
    var(--player-green-r),
    var(--player-green-g),
    var(--player-green-b),
    var(--ratio-8)
  );
  --player-green-accent: rgba(
    calc((var(--player-green-r) + var(--foreground-color-r)) / 2),
    calc((var(--player-green-g) + var(--foreground-color-g)) / 2),
    calc((var(--player-green-b) + var(--foreground-color-b)) / 2),
    var(--ratio)
  );

  --player-red-r: 255;
  --player-red-g: 89;
  --player-red-b: 94;
  --player-red: rgba(
    var(--player-red-r),
    var(--player-red-g),
    var(--player-red-b),
    var(--ratio)
  );
  --player-red-opaque: rgb(
    var(--player-red-r),
    var(--player-red-g),
    var(--player-red-b)
  );
  --player-red-transparent: rgba(
    var(--player-red-r),
    var(--player-red-g),
    var(--player-red-b),
    var(--ratio-8)
  );
  --player-red-accent: rgba(
    calc((var(--player-red-r) + var(--foreground-color-r)) / 2),
    calc((var(--player-red-g) + var(--foreground-color-g)) / 2),
    calc((var(--player-red-b) + var(--foreground-color-b)) / 2),
    var(--ratio)
  );

  --player-yellow-r: 231;
  --player-yellow-g: 197;
  --player-yellow-b: 19;
  --player-yellow: rgba(
    var(--player-yellow-r),
    var(--player-yellow-g),
    var(--player-yellow-b),
    var(--ratio)
  );
  --player-yellow-opaque: rgb(
    var(--player-yellow-r),
    var(--player-yellow-g),
    var(--player-yellow-b)
  );
  --player-yellow-transparent: rgba(
    var(--player-yellow-r),
    var(--player-yellow-g),
    var(--player-yellow-b),
    var(--ratio-8)
  );
  --player-yellow-accent: rgba(
    calc((var(--player-yellow-r) + var(--foreground-color-r)) / 2),
    calc((var(--player-yellow-g) + var(--foreground-color-g)) / 2),
    calc((var(--player-yellow-b) + var(--foreground-color-b)) / 2),
    var(--ratio)
  );

  --current-piece-outline-color: rgba(
    var(--foreground-color-r),
    var(--foreground-color-g),
    var(--foreground-color-b),
    var(--ratio-3)
  );

  --high-contrast-symbol-color: rgba(
    var(--background-color-r),
    var(--background-color-g),
    var(--background-color-b),
    var(--ratio-2)
  );

  --ui-cover-background-color: rgba(
    var(--background-color-r),
    var(--background-color-g),
    var(--background-color-b),
    0.9
  );

  --disabled-foreground-color: rgb(162, 163, 164);
  --hover-foreground-color: rgb(50, 53, 57);
  --active-foreground-color: rgb(73, 75, 78);

  --neutral-background-color: rgb(184, 185, 186);

  --input-background-color: white;
  --placeholder-color: rgb(140, 141, 143);
  --focus-ring-color: rgb(213, 189, 243);

  --space-stroke-color: rgb(140, 141, 143);
  --board-border-color: rgb(207, 207, 207);
}

/* Colors - dark theme */

@media (prefers-color-scheme: dark) {
  :root {
    --background-color-r: 28;
    --background-color-g: 31;
    --background-color-b: 35;
    --background-color: rgb(
      var(--background-color-r),
      var(--background-color-g),
      var(--background-color-b)
    );

    --foreground-color-r: 244;
    --foreground-color-g: 244;
    --foreground-color-b: 244;
    --foreground-color: rgb(
      var(--foreground-color-r),
      var(--foreground-color-g),
      var(--foreground-color-b)
    );

    --disabled-foreground-color: rgb(95, 97, 100);
    --hover-foreground-color: rgb(207, 207, 207);
    --active-foreground-color: rgb(184, 185, 186);

    --neutral-background-color: rgb(73, 75, 78);

    --input-background-color: black;
    --placeholder-color: rgb(117, 119, 121);
    --focus-ring-color: rgb(128, 100, 161);

    --space-stroke-color: rgb(117, 119, 121);
    --board-border-color: rgb(50, 53, 57);
  }
}

/* Typography */

@font-face {
  font-family: "Work Sans";
  src: url('./WorkSans-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 900;
  font-style: normal;
}

/* Base styles */

* {
  box-sizing: border-box;
}

*:focus {
  outline: var(--focus-ring-color) solid var(--border-width-normal);
  outline-offset: var(--size-small-5);
}

html,
body,
#root {
  height: 100%;
  font-family: var(--font-family);
  font-size: var(--size-regular);
  line-height: var(--line-height);
  background-color: var(--background-color);
  color: var(--foreground-color);
}

h1 {
  font-size: var(--size-large-3);
  font-weight: var(--font-weight-heavy);
}

h2 {
  font-size: var(--size-large-2);
  font-weight: var(--font-weight-heavy);
}

/* Basic components */

.input {
  padding: var(--size-small-3) var(--size-small-1);
  font-family: var(--font-family);
  font-size: var(--size-regular);
  font-weight: var(--font-weight-light);
  border: none;
  border-radius: var(--border-radius);
  background-color: var(--input-background-color);
  color: var(--foreground-color);
}

.input::placeholder {
  color: var(--placeholder-color);
}

.button {
  margin: 0;
  font-family: var(--font-family);
  font-size: var(--size-large-1);
  font-weight: var(--font-weight-normal);
  line-height: var(--line-height);
  color: var(--foreground-color);
  background-color: transparent;
  border: var(--border-width-normal) solid currentColor;
  padding: var(--size-small-2) var(--size-regular);
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  border-radius: var(--border-radius);
  transition: color var(--transition-short),
    border-color var(--transition-short);
}

.button.compact {
  font-size: var(--size-regular);
  padding: var(--size-small-3) var(--size-small-1);
}

.button.no-padding {
  padding: 0;
}

.button-group .button {
  border-radius: 0;
}

.button-group .button:first-child {
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}

.button-group .button:last-child {
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.button.hover {
  color: var(--hover-foreground-color);
}

.button:active {
  color: var(--active-foreground-color);
}

.button:disabled,
.button.disabled {
  color: var(--disabled-foreground-color);
  border-color: var(--disabled-foreground-color);
  pointer-events: none;
  cursor: default;
}

.header,
.footer {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
}

.link {
  color: var(--foreground-color);
  font-size: var(--size-large-1);
  transition: color var(--transition-short);
}

.link:hover {
  color: var(--hover-foreground-color);
}

.link:active {
  color: var(--active-foreground-color);
}

.link.compact {
  font-size: var(--size-regular);
}

/* Layout */

.container {
  width: var(--size-large-12);
  max-width: 100%;
  margin: 0 auto;
  padding: var(--size-large-1);
}

.container.rows {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--size-regular);
}
