.form {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: var(--size-large-1);
}

.sectionHeading {
  font-size: var(--size-large-1);
  font-weight: var(--font-weight-heavy);
}

.player {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: var(--size-small-1);
  align-items: center;
}

.player .input {
  grid-column: 2 / 3;
}
