.space {
  stroke: var(--space-stroke-color);
}

.piece.blue {
  fill: var(--player-blue);
}

.piece.green {
  fill: var(--player-green);
}

.piece.red {
  fill: var(--player-red);
}

.piece.yellow {
  fill: var(--player-yellow);
}
