.colorIcon {
  display: inline-block;
  width: var(--size-large-1);
  height: var(--size-large-1);
  border-radius: 100%;
  vertical-align: middle;
}

.colorIcon.blue {
  background-color: var(--player-blue);
}

.colorIcon.green {
  background-color: var(--player-green);
}

.colorIcon.red {
  background-color: var(--player-red);
}

.colorIcon.yellow {
  background-color: var(--player-yellow);
}
