.playerSelect {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: var(--size-regular);
  align-items: center;
}

.playerSelect h1 {
  grid-column: 1 / 3;
}

.pieceControls,
.availablePieces {
  display: grid;
  grid-auto-rows: var(--size-large-4);
  justify-items: stretch;
}

.pieceControls {
  grid-template-columns: repeat(6, minmax(var(--size-large-4), 1fr));
  gap: 0;
}

.availablePieces {
  grid-template-columns: repeat(auto-fill, minmax(var(--size-large-4), 1fr));
  gap: var(--size-regular);
}

.controlButton,
.pieceButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.controlButton + .controlButton {
  margin-left: calc(var(--border-width-normal) * -1);
}

.pieceButton.active.blue {
  border-color: var(--player-blue);
  background-color: var(--player-blue-transparent);
}

.pieceButton.active.green {
  border-color: var(--player-green);
  background-color: var(--player-green-transparent);
}

.pieceButton.active.red {
  border-color: var(--player-red);
  background-color: var(--player-red-transparent);
}

.pieceButton.active.yellow {
  border-color: var(--player-yellow);
  background-color: var(--player-yellow-transparent);
}

.playerMessage {
  font-size: var(--size-large-1);
  padding: var(--size-regular);
  background-color: var(--neutral-background-color);
}

.playerMessage.blue {
  background-color: var(--player-blue);
}

.playerMessage.green {
  background-color: var(--player-green);
}

.playerMessage.red {
  background-color: var(--player-red);
}

.playerMessage.yellow {
  background-color: var(--player-yellow);
}

.finalScore {
  position: relative;
  font-size: var(--size-large-1);
  padding: var(--size-regular);
  border: var(--border-width-light) solid var(--board-border-color);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: var(--size-small-1);
}

.winner {
  grid-column: 1 / 3;
  text-align: center;
  font-weight: var(--font-weight-heavy);
}

.winner.blue {
  color: var(--player-blue);
}

.winner.green {
  color: var(--player-green);
}

.winner.red {
  color: var(--player-red);
}

.winner.yellow {
  color: var(--player-yellow);
}

.score {
  text-align: right;
}

.suspenseCover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--background-color);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity var(--transition-short);
}

.suspenseCover.hidden {
  opacity: 0;
}

.gameUi {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--size-regular);
  position: relative;
}

.uiCover {
  position: absolute;
  top: calc(var(--size-small-2) * -1);
  right: calc(var(--size-small-2) * -1);
  bottom: calc(var(--size-small-2) * -1);
  left: calc(var(--size-small-2) * -1);
  background-color: var(--ui-cover-background-color);
  padding: var(--size-large-1);
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.uiCover .message {
  padding: var(--size-regular);
  background-color: var(--background-color);
  box-shadow: 0 0 var(--size-large-2) var(--size-small-1)
    var(--background-color);
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--size-regular);
  justify-items: center;
}
