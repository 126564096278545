.button {
  border: none;
  padding: 0;
  background: transparent;
  display: inline-block;
  width: var(--size-large-2);
  height: var(--size-large-2);
  color: var(--foreground-color);
}

.close {
  width: 100%;
  height: 100%;
}

.close path {
  stroke: currentColor;
  stroke-width: 0.1;
}
